import React from 'react';
import {Link} from 'react-router-dom';
import './SuccessPas.css';

function SuccessPas() {
  return (
    <div className="success-container">
        <div className="success-title">
        </div>
        <i className="fa-regular fa-circle-check"></i>
        <div className="success-message">
            <p style={{textAlign: 'center'}}><b>Archivo cargado correctamente</b> <br/> Ya puedes regresar al pedido</p>
        </div>
        <div className="success-btn-container">
            <Link className="btn btn-enviar" to={'/'}>Regresar</Link>
        </div>
    </div>
  )
}

export default SuccessPas