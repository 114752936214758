import { useRouteError } from "react-router-dom";

export default function ErrorConfirmar() {
  const error = useRouteError();

  return (
    <div id="error-page" style={{textAlign: 'center'}}>
      <h1>Oops!</h1>
      <p>Se necesita subir un archivo para ver esta página</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}