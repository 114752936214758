import React from 'react';

import './Alerts.css';

function Alerts(props) {
    const {type, message} = props.alert;

    const classString = 'alert-box ' + type + '-alert';

    const typeMsg = type === 'error' ? 'ERROR: ' : 'ÉXITO: '

  return (
    <div className='alert-content'>
      <div className={classString}>
        <p><strong style={{fontWeight:'bold'}}>{typeMsg}</strong>{message}</p>
      </div>
    </div>
  )
}

export default Alerts