import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import CargaPas from "./Components/PAS/CargaPas/CargaPas";
import ConfirmarPas from './Components/PAS/ConfirmarPas/ConfirmarPas'
import ErrorPage from './Components/ErrorPage/ErrorPage'
import ErrorConfirmar from './Components/PAS/ConfirmarPas/ErrorConfirmar';
import SuccessPas from './Components/PAS/SuccessPas/SuccessPas';

const router = createBrowserRouter([
  {
    path: "/",
    element: <CargaPas />,
    errorElement: <ErrorPage />
  },
  {
    path: "confirmarPas",
    element: <ConfirmarPas />,
    errorElement: <ErrorConfirmar />
  },
  {
    path: "pasCargado",
    element: <SuccessPas />,
  }
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;