import React, { Fragment, useState } from 'react'
import {useLocation, Link, useNavigate} from 'react-router-dom';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import './ConfirmarPas.css'

function ConfirmarPas() {
  const [isLoading, setIsLoading] = useState(false);
  // location para leer los datos que enviamos con useNavigate()
  const location = useLocation();
  const navigate = useNavigate();

  const data = {...location.state.response};

  // Generamos la lista de elementos de la tabla
  const rows = data.data.map((row, index) => {
    return (
      // Fragment se utiliza para renderizar multiples elementos
      <Fragment key={index}>
        <tr className="table-rows">
          <td>{row.field_1418}</td>
          <td>{row.field_1419}</td>
          <td className="center">{row.field_1420}</td>
          <td>{row.field_1293}</td>
          <td>{row.field_1421}</td>
          <td className="center">{row.field_1351}</td>
          <td>$ {row.field_1367}</td>
          <td>$ {row.field_1352}</td>
          <td>$ {row.field_1368}</td>
          <td>$ {row.field_1369}</td>
          <td className="center">{row.field_1370}</td>
        </tr>
      </Fragment>
    );
  });

  const handleSubmitForm = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      const response = await fetch('https://securitaspas.appdabi2.com/api/v1/sendData', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {"Content-Type": "application/json"}
      });
      // Transformamos los datos en json
      const linkData = await response.json();

      if(linkData.status === 'error') {
        setIsLoading(false);
        // Regresamos a la página principal con un mensaje de error
        navigate('/', {state: {status: 'error', message: 'Error al cargar la información'}});
      } else {
        setIsLoading(false);
        // Redirigimos al portal de securitas proyectos
        navigate('/pasCargado');
      }
      
    } catch (error) {}
  }

  return (
    <div>
      {isLoading ? <LoadingSpinner /> : <div/>}

      <div className="pas-content">
        <div className="pas-header">
            <h1>Revisión del archivo</h1>
            <h3><span>Cliente:</span> {data.additional.cliente}</h3>
            <h3><span>Proyecto:</span> {data.additional.proyecto}</h3>
            <h3><span>Pedido:</span> {data.additional.pedido}</h3>
        </div>

        <div className="table-pas">
            <table>
              <thead>
                <tr className="sticky-header">
                    <th>Sección</th>
                    <th>Sistema</th>
                    <th>Partida</th>
                    <th>Descripción</th>
                    <th>Modelo / Item</th>
                    <th>Cantidad</th>
                    <th>Costo Producto</th>
                    <th>Precio Producto (USD)</th>
                    <th>Costo Mano Obra (MXN)</th>
                    <th>Precio Mano Obra</th>
                    <th>Tiempo (Hrs)</th>
                </tr>
              </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        </div>    
        <p><span>Registros totales:</span> {data.totalRows}</p>

        <br/>
        <form onSubmit={handleSubmitForm}>
            <button className="btn btn-enviar" type="submit" disabled={isLoading}>Subir</button>
            {/* <a className="btn btn-regresar" href="/api/v1/readPas">Regresar</a> */}
            <Link to={'/'} className='btn btn-regresar'>Regresar</Link>
        </form>
      </div>
    </div>
  )
}

export default ConfirmarPas